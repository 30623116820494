<template>
    <!-- <topNav class="top" :style="style"></topNav> -->
    <swBanner :list = 'list'></swBanner>
    <div class="mt60">
        <topic :topicName = 'topicName[0]'></topic>
        <div class="con">
            <conList :coList = 'coList'></conList>
        </div>
        <div class="con">
            <conList :coList = 'coList1'></conList>
        </div>
    </div>
    <!--  -->

    <!--  -->
    <div class="as mt60">
        <div class="mb30">
            <topic :topicName = 'topicName[1]'></topic>
        </div>
            <div class="">
                <showImg1 :coList = 'aSlist'></showImg1>
                <showImg1 :coList = 'aSlist1'></showImg1>
            </div>
    </div>

    <div class="mt60">
        <div class="mb30">
            <topic :topicName = 'topicName[2]'></topic>
            <div class="flexCent mt20 f14">本产品采用阶梯定价方式，可购买升级</div>
        </div>
    </div>
    <div class="flexCent mb60">
        <div v-for="i in price" :key="i" class="mLR30 price">
            <div class="f30 tA">{{i.tit}}</div>
            <!-- <div class="line flexCent"></div> -->
            <div class="f22 tA mt20 c2">{{i.price}}<span style="color:#323232; font-size:14px;">元/年</span></div>
        </div>
    </div>

    <div class="serve pTB60">
        <div class="mb30">
            <topic :topicName = 'topicName[3]'></topic>
        </div>
        <div class="flexCent mb30" v-show="mobile == false">
            <div class="flexCent aIC click w240"  v-for="i in con1" :key="i">
                <img class="icon1 mr10" v-bind:src="i.icon" alt="" srcset="">
                <div class="f14 c1 wr">{{i.tit}}</div>
            </div>
        </div>
        <div class="flexCent mb30" v-show="mobile == true">
            <div>
                <div>
                    <div class="flex aIC click w240 mt20"  v-for="i in con1" :key="i">
                        <img class="icon1 mr10" v-bind:src="i.icon" alt="" srcset="">
                        <div class="f14 c1 wr">{{i.tit}}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="btn mLRA">立即体验</div>
    </div>
</template>

<script>
    import swBanner from '../components/swBanner';
    import topic from '../components/topic';
    import conList from '../components/conList.vue';
    import showImg1 from '../components/showImg1'
    // import aSbox from '../components/appScen';
    export default {
        components: {
            swBanner,
            topic,
            conList,
            showImg1,
            // aSbox,
        },
        data(){
            return{
                mobile: false,
            }
        },
        setup(){
            return{
                list:[
                    {img:require("../images/banner/wy.png")},
                    {img:require("../images/banner/yz.png")},
                    
                ],
                topicName: [
                    '产品功能',
                    '应用场景',
                    '定价策略',
                    '安全稳定，优质服务'
                ],
                coList:[
                    {
                        tit:'智能设备',
                        txt:'手机端，一键开启智能设备',
                        icon: require('../images/icons/z.png')
                    },
                    {
                        tit:'任务工单',
                        txt:'一键分派任务工单',
                        icon: require('../images/icons/r.png')
                    },
                    {
                        tit:'巡更巡检',
                        txt:'管理巡更巡检并且智能生成报表',
                        icon: require('../images/icons/x.png')
                    },
                ],
                coList1:[
                    {
                        tit:'报事报修',
                        txt:'实时报修并生成工单',
                        icon: require('../images/icons/bx.png')
                    },
                    {
                        tit:'车辆管理',
                        txt:'智能停车管理，一键缴费',
                        icon: require('../images/icons/car.png')
                    },
                    {
                        tit:'访客管理',
                        txt:'一键管理访客申请，并开启智能门禁',
                        icon: require('../images/icons/f.png')
                    },
                ],
                price:[
                    {
                        tit:'企业专业版',
                        price:'1398',
                    },
                    {
                        tit:'单价',
                        price:'¥99'
                    }
                ],
                con1:[
                    {
                        icon:require("../images/icons/m.png"),
                        tit: '免费产品不限期',
                    },
                    {
                        icon:require("../images/icons/m.png"),
                        tit: '适配手机端，pc端\n完美兼容，快速落地',
                    },
                    {
                        icon:require("../images/icons/m.png"),
                        tit: '一对一专业技术支持，响应速度快\n全力助您产品落地',
                    },
                ],
                aSlist:[
                    {
                        icon: require('../images/c.png'),
                        tit: '小区'
                    },
                    {
                        icon: require('../images/f.png'),
                        tit: '工厂'
                    },
                    {
                        icon: require('../images/mark.png'),
                        tit: '商超'
                    }, 
                ],
                aSlist1:[
                    {
                        icon: require('../images/o.png'),
                        tit: '写字楼'
                    },
                    {
                        icon: require('../images/ho.png'),
                        tit: '酒店'
                    },
                    {
                        icon: require('../images/o.png'),
                        tit: '医院'
                    },
                ]
            }
        },
        mounted(){
            let w = document.documentElement.offsetWidth || document.body.offsetWidth;
            console.log('w',w);
            if(w < 1000){
                this.mobile = true;
            }
            console.log(this.mobile);
        }
    }
</script>

<style scoped>
    @import '../assets/css/font.css';
    @import '../assets/css/layout.css';
    .as{
        width: 100%;
        background: #EBF0F6;
        padding: 60px 0;
    }
    .price{
        width: 200px;
        background: #f1f1f1;
        padding: 60px 0;
    }
    .serve{
        background: #F4F4F4;
    }
</style>