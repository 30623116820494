<template>
  <!-- <swBanner :list = 'list'></swBanner> -->
  <div>
      <img class="b" src="../images/banner/news.png" alt="" srcset="">
  </div>
  <div class="mt60 mb60">
    <!-- <div class="btn mLRA click">查看更多</div> -->
    <div class="mt60 flexCent">
        <img class="noData" src="../images/icons/noData.png" alt="" srcset="">
    </div>
    <div class="c1 f18 mt60 tA mb60">暂无新闻</div>
</div>

</template>

<script>
//  import swBanner from '../components/swBanner';
    // import topic from '../components/topic';
    // import conList from '../components/conList.vue';
    // import conImglist from '../components/conImglist';
    // import showImg from '../components/showImg';
    // import showImg1 from '../components/showImg1'
    // import aSbox from '../components/appScen';

    export default {
        components: {
            // swBanner,
            // topic,
            // conList,
            // conImglist,
            // showImg,
            // showImg1,
        },
        setup(){
            return{
                topicName: [
                    '视觉模组',
                    '解决方案',
                ],
                coList:[
                    {
                        tit:'没有健康码难出行？人脸识别助力健康码无障碍使用',
                        txt:'随着防控力度将进一步收紧，无法出示健康码可能陷入寸步难行的境地。人脸识别与健康码的结合将在极大程度上改善这一问题，保障少数人群也能正常出示健康码，让所有人都能分享到科技进步的红利。',
                        icon: require('../images/c2.png')
                    },
                    
                ],
            }
        }
    }
</script>

<style>
@import '../assets/css/font.css';
    @import '../assets/css/layout.css';
    .b{
        width: 100%;
    }
.join{
    background-image: url('../images/bg4.png');
    width: 100%;
    /* height: 400px; */
    background-size: 100%;
    padding: 80px 0;
}

</style>