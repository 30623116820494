<template>
    <div class="flexCent mt30 mb30 mLRA30" v-show="mobile == false">
        <div v-for="i in coList" :key="i" class="mLR30">
            <div class="">
                <img class="imgs" :src="i.icon" alt="">
            </div>
        </div>
    </div>
    <div class="flexSB">
        <div class="mt30" v-show="mobile">
            <div v-for="i in coList" :key="i" class="mLR30">
                <div class="mb20">
                    <img class="imgsM" :src="i.icon" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            coList: {}
        },
        data(){
            return{
                mobile:false,
            }
        },
        mounted(){
            let w = document.documentElement.offsetWidth || document.body.offsetWidth;
            console.log('w',w);
            if(w < 1000){
                this.mobile = true;
            }
        }
 
    }
</script>

<style scoped>
    @import '../assets/css/font.css';
    @import '../assets/css/layout.css';
    .imgs{
        width: 400px;
        /* height: 290px; */
    }
    .imgsM{
        width: 300px;
        /* height: 290px; */
    }

</style>