<template>
    <div class="flexCent mt30 mb30" v-show="mobile == false">
        <div v-for="i in coList" :key="i" class="tA mLR30 colBox">
            <div>
                <img class="icons" :src="i.icon" alt="">
            </div>
            <div class="f18 mt20">{{i.tit}}</div>
            <div class="wr c1 f14 mt10 mb30">{{i.txt}}</div>
            <div class="btn mLRA click">了解更多</div>
        </div>
    </div>

    <div class="flexCent mt30 mb30" v-show="mobile">
        <div>
            <div v-for="i in coList" :key="i" class="tA colBoxM ">
                <div>
                    <img class="iconsM" :src="i.icon" alt="">
                </div>
                <div class="f18 mt20">{{i.tit}}</div>
                <div class="wr c1 f14 mt10 mb30">{{i.txt}}</div>
                <div class="btn mLRA click">了解更多</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            coList: {}
        },
        data() {
            return{
                mobile: false,
            }
        },
        mounted(){
            let w = document.documentElement.offsetWidth || document.body.offsetWidth;
            console.log('w',w);
            if(w < 1000){
                this.mobile = true;
            }
        }
    }
</script>

<style scoped>

    .icons{
        width: 200px;
        height: 140px;
    }
    .iconsM{
        width: 200px;
        /* height: 140px; */
    }
    .colBox{
        width: 220px;
        padding: 20px 20px;
        background: #f4f4f4;
        border-radius: 10px;
    }
    .colBoxM{
        width: 220px;
        padding: 20px 20px;
        margin-bottom: 30px;
        background: #f4f4f4;
        border-radius: 10px;
    }
    .colBox:hover{
        box-shadow: 0px 1px 6px 0px rgba(16, 17, 19, 0.11);
    }
</style>