<template>
  <swBanner :list = 'list'></swBanner>
  <div class="mt60 mb60">
    <topic :topicName = 'topicName[0]'></topic>
    <div class="mt20">
        <showImg1 :coList = 'coList'></showImg1>
    </div>
    <div class="btn mLRA click">查看更多</div>
</div>
<div class="mt60 bg">
    <topic :topicName = 'topicName[1]'></topic>
    <div class="mt20">
        <showImg1 :coList = 'coList1'></showImg1>
    </div>
    <div class="btn mLRA click">查看更多</div>
</div>
<div class="join">
    <div class="">
        <div class="f28 fw tA">期待您的加入</div>
        <div class="f14 tA mt20 mb40">真诚邀您成为我们的合作伙伴，共建点到开放平台产业链市场</div>
        <div class="btn mLRA">申请合作</div>
    </div>
</div>

</template>

<script>
 import swBanner from '../components/swBanner';
    import topic from '../components/topic';
    // import conList from '../components/conList.vue';
    // import conImglist from '../components/conImglist';
    // import showImg from '../components/showImg';
    import showImg1 from '../components/showImg1'
    // import aSbox from '../components/appScen';

    export default {
        components: {
            swBanner,
            topic,
            // conList,
            // conImglist,
            // showImg,
            showImg1,
        },
        setup(){
            return{
                list:[
                    {img:require("../images/banner/wy.png")},
                    {img:require("../images/banner/yz.png")},
                    
                ],
                topicName: [
                    '视觉模组',
                    '解决方案',
                ],
                coList:[
                    {   
                        tit:'USB单目人脸识别摄像头模组',
                        icon: require('../images/c1.png')
                    },
                    {
                        tit:'USB双目人脸识别摄像头模组',
                        icon: require('../images/c2.png')
                    },
                    {
                        tit:'D01双目宽动态摄像头',
                        icon: require('../images/c3.png')
                    },
                ],
                coList1:[
                    {   
                        tit:'大屏数据展示方案',
                        icon: require('../images/big2.png')
                    },
                    {
                        tit:'手机端操作App',
                        icon: require('../images/yZ.png')
                    },
                    {
                        tit:'pc后台管理系统',
                        icon: require('../images/pc.png')
                    },
                ],
            }
        }
    }
</script>

<style>
@import '../assets/css/font.css';
    @import '../assets/css/layout.css';
.join{
    background-image: url('../images/bg4.png');
    width: 100%;
    /* height: 400px; */
    background-size: 100% 100%;
    padding: 80px 0;
}

</style>