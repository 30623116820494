<template class='c0'>
    <div class="priBanner cf mb40">
        <div class="tA f30 mb40">选择适合您的产品</div>
        <div class="tA f18">咨询电话：<span class="c2">400-822-5266</span></div>
    </div>
    <div class="con">
        <div class="flex bigBox">
            <div v-for="i in pri" :key="i" class="w450">
                <div class="box tA f16">{{i.tit}}</div>
                <div class="box2 tA f14">{{i.price}}</div>
                <div class="box2 tA f14">{{i.txt}}</div>
                <div class="box3 tA flexCent">
                    <div class="btn " v-if="i.btn !== ''">
                        {{i.btn}}
                    </div>
                </div>
                <div class="box tA f16">{{i.func}}</div>
                <div class="box1 tA f14">{{i.f1}}</div>
                <div class="box1 tA f14">{{i.f2}}</div>
                <div class="box1 tA f14">{{i.f3}}</div>
                <div class="box1 tA f14">{{i.f4}}</div>
                <div class="box1 tA f14">{{i.f5}}</div>
            </div>
        </div>
    </div>
    <div class="mt60 mb30">
        <div class="tA f18 fw">标准版功能说明</div>
    </div>
    <div class="mb60">
        <div class="con">
            <div class="flex bigBox">
                <div >
                    <div class="box3 tA f16 w200 fw">功能模块</div>
                    <div v-for="i in fn" :key="i" class="box4 tA f14 w200">{{i}}</div>
                </div>
                <div>
                    <div class="box3 f16 w300 fw">子功能块</div>
                    <div v-for="i in fn1" :key="i" class="box5 f14 w300">{{i}}</div>
                </div>
                <div>
                    <div class="box3 f16 w620 fw">高级功能</div>
                    <div v-for="i in fn2" :key="i" class="box5 f14 w620">{{i}}</div>
                </div>
            
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            pri:[
                {
                    tit:'',
                    price:'价格',
                    txt:'说明',
                    btn:'',
                    func:'功能',
                    f0:'数据中心',
                    f1:'微信设置',
                    f2:'支付设置',
                    f3:'出入管理',
                    f4:'小区服务',
                    f5:'收费管理',
                    f6:'一物一码',
                },
                {
                    tit:'免费版',
                    price:'0 /户/年',
                    txt:'小区房屋、住户、车辆等数据统一管理，通过微信公众号轻松发布小区公告，同时还可实现小区电子化出入管理',
                    btn:'立即申请',
                    func:'',
                    f0:'√',
                    f1:'√',
                    f2:'√',
                    f3:'√',
                    f4:'-',
                    f5:'-',
                    f6:'-',
                },
                {
                    tit:'标准版',
                    price:'¥10 /户/年\n（300户起售）',
                    txt:'通过微信公众号即可为住户提供公告、报修等物业服务，同时完备的物业收费管理功能，轻松实现账单生成、自动催缴、手机缴费等功能，还可多人协同提高物业办公效率，满足小区日常管理需求',
                    btn:'立即申请',
                    func:'',
                    f0:'√',
                    f1:'√',
                    f2:'√',
                    f3:'√',
                    f4:'√',
                    f5:'√',
                    f6:'√',
                }
            ],
            fn:[
                '数据中心','微信设置','支付设置','出入管理','小区服务',
                
            ],
            fn1:[
                '小区管理、楼宇管理、房屋管理、住户管理、车辆管理、车位管理、数据标签、小区微站、数据导出',
                '微信公众号设置（自定义菜单、自定义回复）、微信小程序设置',
                '支付接口代申请（微信、支付宝）及相关设置',
                '电子通行证、访客管理、出门条管理、出入记录、智能门禁、智能停车',
                '小区公告、客服中心、短信群发、图文素材、服务预订、小区活动',
            ],
            fn2:[
                '对小区、人、车、房进行数据管理，一键导入数据，人车房轻松关联查询。数据自由导出，可以随时迁移到其它平台，而不必担心被限制',
                '可使用物业独立公众号、小程序，轻松打造物业在线服务品牌，多个小区也可使用同一个公众号管理',
                '一站式代申请微信、支付宝等平台支付接口，物业也可轻松实现移动收缴，线上线下收费同样方便，不同小区可实现独立核算统一入账，确保资金安全。独有微信代扣物业费功能，轻松实现物业费每月自动扣款。',
                '以扫码通行为核心，实现住户、访客、外部等不同身份人员统一出入管理机制，同时还提供了出门条管理、出入记录、观察员等多种功能，系统实施部署成本低廉，出入管理功能向所有微小区用户免费开放',
                '全面赋能物业移动服务能力，无论公告还是报修，均可通过手机完成，更有服务预订、小区活动等功能，全方位提高业主满意度',
            ]
        }
    }
}
</script>

<style>
    .priBanner{
        background: #222647;
        width: 100%;
        padding: 100px 0;
    }
    .bigBox{
      box-shadow: 0px 16px 20px 0px rgb(16 17 19 / 8%);  
    }
    .box{
        height: 50px;
        line-height: 50px;
        background: #f6f6f6;
        border: #f6f6f6 1px solid;
    }
    .box1{
        white-space: pre-line;
        height: 50px;
        line-height: 50px;
        border: #f6f6f6 1px solid;
    }
    .box2{
        white-space: pre-line;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        border: #f6f6f6 1px solid;
    }
    .box3{
        white-space: pre-line;
        height:80px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        border: #f6f6f6 1px solid;
    }
    .box4{
        white-space: pre-line;
        height:120px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        border: #f6f6f6 1px solid;
    }
    .box5{
        white-space: pre-line;
        height:120px;
        display: flex;
        align-items: center;
        /* justify-content: center; */
        padding: 0 20px;
        border: #f6f6f6 1px solid;
    }
</style>