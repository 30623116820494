<template>
    <swBanner :list = 'list'></swBanner>
    <div class="mt60">
        <topic :topicName = 'topicName[0]'></topic>
        <div class="tA f14 mt20">
            通过物业基本管理功能，提供物业线上管理的实时效率性，<br>打造智慧 高效 方便 快捷的智慧小区</div>
        <div class="mt20 con">
            <conList :coList = 'coList'></conList>
            <conList :coList = 'coList1'></conList>
        </div>
    </div>
    <div class="qr mt60">
        <topic :topicName = 'topicName[1]'></topic>
        <div class="tA f14 mt20">
            一物一码可以自带“信息”和“状态”，替代传统纸质表格管理，<br>减轻繁琐手写流程，提高物业工作效率可用于信息收集、<br>设备巡检、资产表示等等</div>
        <div class="mt40 flexCent">
            <img class="aBg " src="../images/aBg.png" alt="" srcset="" v-show="mobile == false">
            <img class="aBM" src="../images/aBg.png" alt="" srcset="" v-show="mobile">
        </div>
    </div>

    <div class="mt60">
        <topic :topicName = 'topicName[2]'></topic>
        <div class="tA f14 mt20">充分利用物联网结合小区物业基本管理功能，为小区安防树立了一道保障及便利了业主生活</div>
        <conImglist :coList = 'coList2'></conImglist>
    </div>

    <div class="qr mt60">
        <topic :topicName = 'topicName[3]'></topic>
        <div class="tA f14 mt20">有效提高信息数据在小区物业中的高效利用，降低管理成本和人员成本</div>
        <div class="con">
            <showImg :coList = 'coList3'></showImg>
        </div>
    </div>
    <div class=" mt60">
        <topic :topicName = 'topicName[4]'></topic>
        <showImg1 :coList = 'coList4'></showImg1>
        <div class="tA mb60">更多合作伙伴即将加入我们</div>
    </div>
    

</template>

<script>
    import swBanner from '../components/swBanner';
    import topic from '../components/topic';
    import conList from '../components/conList.vue';
    import conImglist from '../components/conImglist';
    import showImg from '../components/showImg';
    import showImg1 from '../components/showImg1'
    // import aSbox from '../components/appScen';

    export default {
            components: {
            swBanner,
            topic,
            conList,
            conImglist,
            showImg,
            showImg1,
        },
        data(){
            return{
                mobile:false,
            }
        },
        setup(){
            return{
                list:[
                    {img:require("../images/banner/wy.png")},
                    {img:require("../images/banner/yz.png")},
                    
                ],
                topicName: [
                    '智慧物业解决方案',
                    '一物一码专业解决方案',
                    '多套硬件专业解决方案',
                    '强大的可视化大数据分析统计',
                    '我们的合作伙伴'
                ],
                coList:[
                    {
                        tit:'智能设备',
                        txt:'手机端，一键开启智能设备',
                        icon: require('../images/icons/z.png')
                    },
                    {
                        tit:'任务工单',
                        txt:'一键分派任务工单',
                        icon: require('../images/icons/r.png')
                    },
                    {
                        tit:'巡更巡检',
                        txt:'管理巡更巡检并且智能生成报表',
                        icon: require('../images/icons/x.png')
                    },
                ],
                coList1:[
                    {
                        tit:'公告通知',
                        txt:'发布公告通知后即时推送',
                        icon: require('../images/icons/z.png')
                    },
                    {
                        tit:'在线缴费',
                        txt:'查询相关费用后即可在线缴费',
                        icon: require('../images/icons/r.png')
                    },
                    {
                        tit:'报事报修',
                        txt:'实时报修并生成工单',
                        icon: require('../images/icons/bx.png')
                    },
                ],
                coList2:[
                    {
                        tit:'智慧停车',
                        txt:'多种车辆收费类型管理，满足小区各类情景收费；零停留，免取卡进出；停车系统统一管理，有效保障车辆安全；自助缴费，避免错缴漏缴；支持运营广告，给小区物业增收',
                        icon: require('../images/stop.png')
                    },
                    {
                        tit:'人脸识别摄像头',
                        txt:'人物实时监控，保障小区基本安全；人员关怀，有效提升小区业主幸福指数；行为检测报警，安全监控升级；业主可实时查看监控视频，增加业主放心指数；支持二次开发，可对接更多场景',
                        icon: require('../images/cam.png')
                    },
                    {
                        tit:'人脸识别门禁',
                        txt:'人脸，独一无二的门禁“钥匙”，解放业主双手；人脸识别活体检测安全有保障；出入关怀，给业主增添一份放心；支持运营广告，给小区物业增收；支持二次开发，可对接更多场景',
                        icon: require('../images/dor.png')
                    },
                ],
                coList3:[
                    {
                        icon: require('../images/big.png')
                    },
                    {
                        icon: require('../images/big1.png')
                    },
                ],
                coList4:[
                    {   
                        tit:'无锡国家软件园',
                        icon: require('../images/wx.png')
                    },
                    {
                        tit:'世界贸易中心',
                        icon: require('../images/sm.png')
                    },
                    {
                        tit:'新都商务广场',
                        icon: require('../images/xd.png')
                    },
                ],
            }
        },
        mounted(){
            let w = document.documentElement.offsetWidth || document.body.offsetWidth;
            console.log('w',w);
            if(w < 1000){
                this.mobile = true;
            }
        }
    }
</script>

<style  scoped>

    .qr{
        width: 100%;
        background: #EBF0F6;
        padding: 60px 0;
    }
    .aBg{
        width: 700px;
        height: 540px;
    }
    .aBM{
        width: 340px;
        height: 340px;
    }
</style>