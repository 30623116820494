<template>
  <swBanner :list = 'list'></swBanner>
  <div class="mt60 mb60">
    <topic :topicName = 'topicName[0]'></topic>
    <div class="mt20">
        <showImg1 :coList = 'coList4'></showImg1>
    </div>
    <div class="btn mLRA">查看更多</div>
</div>
</template>

<script>
 import swBanner from '../components/swBanner';
    import topic from '../components/topic';
    // import conList from '../components/conList.vue';
    // import conImglist from '../components/conImglist';
    // import showImg from '../components/showImg';
    import showImg1 from '../components/showImg1'
    // import aSbox from '../components/appScen';

    export default {
        components: {
            swBanner,
            topic,
            // conList,
            // conImglist,
            // showImg,
            showImg1,
        },
        setup(){
            return{
                list:[
                    {img:require("../images/banner/wy.png")},
                    {img:require("../images/banner/yz.png")},
                    
                ],
                topicName: [
                    '产品公开课',
                    '技术公开课',
                    '解决方案公开课',
                ],
                coList4:[
                    {   
                        tit:'智慧物业管理平台公开课',
                        icon: require('../images/crouse.png')
                    },
                    {
                        tit:'智慧物业管理平台公开课',
                        icon: require('../images/crouse.png')
                    },
                    {
                        tit:'智慧物业管理平台公开课',
                        icon: require('../images/crouse.png')
                    },
                ],
            }
        }
    }
</script>

<style>

</style>