<template>
    <!-- banner -->
    <div class=" mb40">
        <img class="banner" src="../images/banner/login.png" alt="" srcset="">
    </div>
    <!-- logIn -->
    <topic class="flexCent " :topicName = 'topicName[0]'/>
    <div class="mLRA flexCent ">
        <div class="tA mb60 mt30 mr40">
            <div>
                <img class="pcLogin" src="../images/pcLogin.png" alt="" srcset="">
            </div>
            <div class="mb20 f18">
                点到停车管理后台
            </div>
            <a href="https://mu.ddaop.com" class="log">立即登陆</a>
        </div>
        <div class="tA ml40 mr40 mt30" >
            <div>
                <img class="pcLogin" src="../images/pcLogin2.png" alt="" srcset="">
            </div>
            <div class="mb20 f18">
                智慧物业管理服务平台
            </div>
            <a href="https://www.zhwgyun.com/zhwgyun/Back/main.html" class="log">立即登陆</a>
        </div>
        <div class="tA ml40 mt30" >
            <div>
                <img class="pcLogin" src="../images/pcLogin2.png" alt="" srcset="">
            </div>
            <div class="mb20 f18">
                岗亭收费服务管理平台（测试）
            </div>
            <a href="https://www.tckx365.com:10000" class="log">立即登陆</a>
        </div>

        <!-- <div class="tA">
            <div>
                <img class="pcLogin" src="../images/pcLogin2.png" alt="" srcset="">
            </div>
            <div class="mb20 f18">
                未注册用户请扫描二维码<br>并联系管理员获取后台账户
            </div>
            <div>并联系管理员获取后台账户</div>
            <a href="https://www.zhwgyun.com/zhwgyun/Back/main.html" class="log">立即登陆</a>
        </div> -->
    </div>
    <topic class="flexCent mt20" :topicName = 'topicName[1]'/>
    <div class="flexCent mb60">
        <div class="tA mt30 mr40">
            <div>
                <img class="bD" src="../images/bD1.png" alt="" srcset="">
            </div>
            <div class="mb20 f18">
                智慧物业项目管理服务平台
            </div>
            <a href="https://www.zhwgyun.com/zhwgyun/Look_property/login.html" class="log">立即登陆</a>
        </div>
        <div class="tA mt30 mb60 ml40">
            <div>
                <img class="bD" src="../images/bD2.png" alt="" srcset="">
            </div>
            <div class="mb20 f18">
                智慧物业公司管理服务平台
            </div>
            <a href="https://www.zhwgyun.com/zhwgyun/Look/" class="log">立即登陆</a>
        </div>

    </div>

</template>

<script>
    import topic from '../components/topic';

    export default {
        components: {
            topic,
        },
        setup(){
            return{
                topicName: [
                    '后台管理',
                    '数字大屏'
                ],
            }
        }
    }
</script>

<style>
    @import '../assets/css/font.css';
    @import '../assets/css/layout.css';
    .pcLogin{
        width: 240px;
        height: 240px;
    }
    .log{
        border: 1px solid #4988FD;
        padding: 4px 20px;
        color: #4988FD;
    }
    .bD{
        width: 340px;
        border-radius: 8px;
        margin-bottom: 20px;
    }
    .banner{
        width: 100%;
    }
</style>